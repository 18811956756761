<template>
  <div class="details">
    <img src="../../assets/11.png" alt="" v-if="index==1" style="width:100%;">
    <img src="../../assets/22.png" alt="" v-if="index==2" style="width:100%;">
    <img src="../../assets/33.png" alt="" v-if="index==3" style="width:100%;">
    <img src="../../assets/44.png" alt="" v-if="index==4" style="width:100%;">
    <img src="../../assets/55.png" alt="" v-if="index==5" style="width:100%;">
    <img src="../../assets/66.png" alt="" v-if="index==6" style="width:100%;">
  </div>
</template>

<script>
export default {
  name: '',
  props:{},
  data () {
    return {
      index:0
    }
  },
  components: {},
  methods: {},
  mounted() {
    this.index=this.$route.query.id
  },
  computed: {},
  watch: {}
}
</script>

<style lang='scss' scoped>
.details{
  width: 100%;
  height: 100%;
}
</style>